/*
 * @Descripttion:
 * @version:
 * @Author: ared
 * @Date: 2022-10-27 14:46:04
 * @LastEditors: ared
 * @LastEditTime: 2022-11-23 14:51:56
 */
import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { goodsList } from "@home3/homeType";
import { wrapGoodCDN } from "@/utils/cdn";
import { convertToI18 } from "@/utils/count";
type Props = {
  goodsList: goodsList;
};
const Index = (props: Props) => {
  const { t, i18n } = useTranslation("home");
  let { goodsList } = props;

  const threeGoodsList = (goodsList || []).slice(0, 3);
  const handClick = (suburl: string) => {
    window.location.href =
      process.env.NEXT_PUBLIC_URL + "/mobile/products/products";
  };
  return (
    <div className="bg-[#fff] px-[20px]">
      <div className={styles.title + " pb-[20px]"}>
        {t("goodRanking24")}
        <div className={styles.more} onClick={() => handClick("Products")}>
          {t("_more")}
        </div>
      </div>
      {/* <div className={styles.time}>{t("update12hour")}</div> */}
      <div className={styles.main}>
        <div className={styles.itemBox}>
          {(threeGoodsList || []).map((item, index: any) => (
            <div
              key={index}
              className={styles.item}
              onClick={() => handClick("Products")}
            >
              <div className="flex items-center">
                <div className={styles.sort}>#{index + 1}</div>
                <div className={styles.atr}>
                  <Image
                    lazyBoundary="10px"
                    src={wrapGoodCDN(
                      item.imagesPrivatization
                        ? item.imagesPrivatization[0]
                          ? item.imagesPrivatization[0]
                          : ""
                        : "",
                      48 * 3
                    )}
                    width={480}
                    height={480}
                    objectFit="cover"
                    alt=""
                    className="rounded-[8px] !border-[1px] !border-solid !border-[#ECECEC]"
                  ></Image>
                </div>
                <div className={styles.info}>
                  <div className={styles.name}>{item?.title}</div>
                  <div className={styles.price}>
                    {t("price")}: ${" " + item?.price}
                  </div>
                  <div className={styles.count}>
                    <div>{t("dayGMV")}</div>
                    <div className="ml-[4px]">{item?.salesAmountUsd}</div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className={styles.add}>
                  {convertToI18(item?.soldCount)}
                </div>
                <div className={styles.grow}>{t("daySale")}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Index;
